import React from "react";

// import "./Editor.css";
import useChat from "../useChat";
import { useEffect, useRef } from "react";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";

const Editor = () => {
  const { messages, sendMessage, setMessage, deleteMessage } =
    useChat("editor");
  const [newMessage, setNewMessage] = React.useState("");

  const handleNewMessageChange = (event) => {
    setNewMessage(event.target.value);
  };

  const handleSendMessage = () => {
    sendMessage(newMessage);
    setNewMessage("");
  };

  const messagesEndRef = useRef(null);
  const scrollToBottom = () => {
    if (messagesEndRef && messagesEndRef.current /* + other conditions */) {
      messagesEndRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        if (newMessage?.length > 0) handleSendMessage();
      }}
    >
      <div className="chat-room-container">
        <div className="messages-container">
          <ul className="messages-list">
            {messages?.map((message, i) => {
              var num = Math.floor(Math.random() * 10);
              var splitedMessage = message.body.split("-");
              var body = splitedMessage[0] || "";
              var user = "";

              if (splitedMessage?.length > 1) {
                user = splitedMessage[1];
              }

              return (
                <li
                  key={i}
                  onClick={() => {
                    confirmAlert({
                      title: "Are you sure you want to delete?",
                      message:
                        "This action cannot be reverted, please double check before deleting this",
                      buttons: [
                        {
                          label: "Yes",
                          onClick: () => deleteMessage(message),
                        },
                        {
                          label: "No",
                        },
                      ],
                    });
                  }}
                >
                  <a href="#">
                    {/* <img src="./delete.png" width={20} className="delete-icon" />{" "} */}
                    <h2>{body}</h2>
                    {user != "" && (
                      <label className="user-label">
                        <span>{" - "}</span> <span>{user}</span>
                      </label>
                    )}
                  </a>
                </li>
              );
            })}
            <div
              ref={messagesEndRef}
              style={{ background: "red", height: "10px" }}
            />
          </ul>
        </div>
        <input
          value={newMessage}
          onChange={handleNewMessageChange}
          placeholder="Write message..."
          className="new-message-input-field"
        />
        <div className="button-container">
          <button
            type="button"
            onClick={() => {
              confirmAlert({
                title: "Are you sure you want to delete all messages?",
                message:
                  "This action cannot be reverted, please double check before deleting this",
                buttons: [
                  {
                    label: "Yes",
                    onClick: () => deleteMessage({ id: "ALL" }),
                  },
                  {
                    label: "No",
                  },
                ],
              });
            }}
            className="delete-message-button"
          >
            Delete All
          </button>
          <button className="send-message-button">Send</button>
        </div>
      </div>
    </form>
  );
};

export default Editor;
